.clases-de-bajo .cl-descrip {
  max-width: 720px;
}

.back {
  width: 100%;
  background-size: cover;
  /*background-attachment: fixed;*/
}

main a:hover {
  text-decoration: none;
}

main .back1 {
  width: 100%;
  /* position: relative;
  max-height: 948px; */ /*32*/
  
  /* background-image: url("/assets/img/portada_web_nov23.jpg"); */
  /* background-position: center; */
  
  /* height: 95vh;
  background-position: top; */
}
  /* .filter {
    height: 100%;
    position: relative;
    background-color: hsla(0, 10%, 0%, 0.1);
  } */

  .space {
    height: 6rem;
  }
  .content {
    background-color: hsl(180, 20%, 96%);
}


.space {
  background-color: hsla(215, 75%, 60%, 0.45);
}

  .back2 {
    background-image: url('/assets/img/clases.jpg');
    background-attachment: fixed;
    /* background-position: 0 -36rem; */
  }


  .back3 {
    /*background-image: url("/assets/img/bajos.jpg");*/
    background-attachment: fixed;
  }
    .back3 .space {
      background-color: hsla(0, 10%, 0%, 0.2);
    }


  main .back4 {
    background-image: url("/assets/img/bajos.jpg");
    background-attachment: fixed;
    /* background-position: 0 -36rem; */
  }

@media only screen and (min-width: 1600px) {
  main .back1 {
    /* height: 95vh;
    background-position: top; */
    /* background-position: 0rem -14rem; */
  }
}

@media only screen and (max-width: 991px) {
  main .back1 {
    /* height: 20rem;
    background-position: 0rem -6rem; */
    /* height: 50vh;
    background-position: top; */
  }
}

@media only screen and (max-width: 768px) {
  main .back {
    background-attachment: local;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  main .back1 {
    /* height: 18rem; */
  }

  main .back2 {
    background-position: 0 -12rem;
  }
  .back3 {
    background-position: 0 -24rem;
  }
  .back4 {
    background-position: 0 -14rem;
  }
}

@media only screen and (max-width: 486px) {
  main .back1 {
    /* height: 11rem; */
    /* background-position: 0rem -1rem; */
  }
    .espacio-portada {
      /*height: 12rem;*/
    }

  main .back2 {
    background-position: 0 -8rem;
  }
  /*.back3 {
    background-position: 0 -12rem;
  }*/
  .back4 {
    background-position: 0 -6rem;
  }
    #contacto {
      height: 7rem;
    }
}
