/**** FORM ****/
.form {
  position: relative;
  padding: 1rem 0;
}
.form.container {
  max-width: 640px;
}
    .form .form-control {
      width: 100%;
      /*border: 1px solid hsl(0, 0%, 60%);*/
      border: none;
      border-radius: 0.5rem;
      box-shadow: none;
      /*box-shadow: 1px 1px 0.2rem 0 hsla(0, 0%, 0%, 0.1);*/
      background-color: hsla(0, 0%, 98%, 0.3);
      border: 2px solid var(--color-terciario);


    }

    @media only screen and (max-width: 992px) {
      .form .container {
        max-width: 500px;
      }
    }


.center-y {
  display: flex;
  justify-content: center;
}
  .form-group label {
    color: hsl(0, 0%, 30%);
    width: 100%;
  }

  #response { /* iframe */
    border: none;
    width: 100%; height: 3rem;
    /*background-color: grey;*/
}

  .form .btn {
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    font-weight: 700;
  }

  .form iframe {
    display: inline-block !important;
  }



@media only screen and (max-width: 486px) {
  .form .form-control {
    width: 100%;
  }
}
