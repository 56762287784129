/*** NAVBAR ***/
nav.navbar {
  position: fixed;
    width: 100%;
    /* max-width: 2000px; */
    padding: 0.5rem 3.8rem;
    z-index: 39;
    background-color: var(--color-primario); /*hsl(200, 30%, 96%);*/
    box-shadow: 1px 0 1rem 0 hsla(0, 0%, 0%, 0.6);
    /*border-bottom: 1px solid hsl(200, 50%, 30%);*/
}
    nav .titulo {
      color: var(--color-terciario);
    }
      nav .nombre {
      }
    nav img {
      /* filter: invert(96%); */
    }
    nav span {
      margin: 0 0.3rem 0 .6rem;
    }

    #basic-navbar-nav {
    /*  position: absolute;
      right: 4rem;*/
      justify-content: flex-end;
    }
      div.dropdown-menu.show {
        overflow: hidden;
      }
        nav .nav-link {
          margin: 0 .2rem;
          /* color: white !important; */
        }
          /* nav .nav-link:hover {
            background-color: hsl(200, 50%, 35%);
            border-radius: 50% .5rem;
            transition: .2s;
          } */
            /* nav .nav-link:focus {
              border: 1px dashed var(--background-primario);
              border-radius: 50% .5rem;
            } */

        /* dropdown active format reset */
        div.dropdown-menu a.active {
          color: inherit;
          background-color: inherit;
        }
          div.dropdown-menu a.active:hover {
            background-color: var(--texto-claro);
          }

    /* SOCIAL */
    nav .social {
      display: flex;
        align-items: center;
        padding-left: 1rem;
      /*border-left: 2px solid hsl(200, 40%, 35%);*/
      font-size: 1.1rem;
    }
      nav .social a {
        margin: 0 .4rem;
          color: var(--texto-claro);
      }
      nav .social a:hover {
          color: var(--color-terciario);
      }
  
      /* Svg icons */
      nav .social svg.svg-icon.rrss {
        width: 1.2rem; height: 1.2rem;
        margin-right: 5px;
      }
        nav .social svg.svg-icon.rrss path {
          fill: var(--texto-claro);
        }
        nav .social svg.svg-icon.rrss path:hover {
          fill: var(--color-terciario);
        }



@media only screen and (max-width: 991px) {
  .nav-menu-lg {
    display: none !important;
  }

  .navbar-toggler {
    border: none;
    padding: 0;
    font-size: 1.4rem;
  }

  .navbar-toggler.menu .nav-link {
    text-align: left;
    font-size: 1.2rem;
    margin: .5rem 0;
  }

  nav .nav-link:hover, nav .nav-link:focus,
  .navbar-toggler.menu .nav-link:hover, .navbar-toggler.menu .nav-link:focus {
    background-color: transparent;
    padding-left: 2rem;
    /* color: hsl(200, 30%, 80%) !important; */
  }

  div.dropdown-menu.show {
    border: none;
    border-left: 1px solid hsla(0, 0%, 90%);
    background-color: transparent;
  }
    div.dropdown-menu.show .dropdown-divider {
      display: none;
    }

    nav .social {
      /* position: absolute;
        right: 3.8rem; bottom: 1rem;
      padding-left: 0rem; */
      border-top: 1px dashed var(--background-primario);
      padding: 1rem;
    }
}

@media only screen and (max-width: 768px) {
  nav.navbar {
    padding: 0.5rem 1.8rem;
  }
    .titulo small {
      display: block;
      margin: 0 0.8rem;
    }

    nav .social {
        right: 1.8rem; bottom: 1rem;
    }
}

@media only screen and (max-width: 486px) {
  nav.navbar {
    padding: 0.5rem 1rem;
  }
    .titulo span {
      margin: 0 0 0 0.8rem;
      font-size: 1.2rem;
    }
    .titulo small {
      font-size: 1rem;
    }
    .navbar-toggler {
    }
}
