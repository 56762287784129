.content .cl-descrip {
  width: 60%;
  margin: 0 auto;
  padding: 3rem 0 .5rem 0;
}
#cla {
  /*padding: 0 0 1rem 0;*/
}
  .cl-descrip p {
    margin: 2rem 0;
    padding: 0 2rem;
    border-left: 2px solid var(--color-cinco);
  }
  .cl-descrip h3 {
    width: 50%;
    color: var(--color-cinco);
    /* font-family: 'Bubbler One', sans-serif; */
    font-size: 2rem;
    letter-spacing: 0.4rem;
  }

  .cl-descrip .alert { 
    border: 1px solid var(--color-cinco); 
    text-align: left; 
    max-width: max-content;
  }

  /* iconos */
    .cl-descrip .col {
      display: flex;
        flex-direction: column;
        align-items: center;
    }
      .cl-descrip .circle-icon {
        width: 7rem; height: 7rem;
        display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

        text-align: center;
          color: white;
          border-radius: 100%;
          background-color: var(--color-cinco);
      }
        .cl-descrip .col i {
          font-size: 2.6rem;
        }
          .cl-descrip .col p {
            margin: 0; padding: 0;
          }


  .center {
    display: flex;
    justify-content: flex-start;
  }
  #cla .btn {
    position: relative;
    /*margin: 1.4rem 0 1rem 0;*/
    padding: 0 2rem;
    border-radius: 2rem;
    background-color: transparent;
    border: none;

    color: var(--color-cuatro);
    font-weight: 700;
    font-size: 4rem;
    transition: 0.5s;
  }
    #cla .btn:hover {
      /*text-shadow: 1px 1px 6px hsla(0, 0%, 0%, 0.2);*/
      transform: rotate(1turn);
      transition: 0.5s;
    }

.container-info {
  /*display: none;*/
}
.cl-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 3rem 2rem 3rem;
  background: linear-gradient(to bottom, transparent, hsl(240, 80%, 92%));
}
    .cl-descrip-more {
      margin: 0.5rem;
      width: 30%;
      padding-left: 1rem;
      border-left: 2px solid var(--color-cuatro);
      font-style: italic;
    }
      .cl-descrip-more .title {
        margin-bottom: 0.5rem;
        color: var(--color-cuatro);
        /* font-family: 'Bubbler One', sans-serif; */
        font-size: 1.2rem;
        letter-spacing: 0.4rem;
        font-style: normal;
}

.ini-descrip .nov-vid {
  margin: 0 auto;
  max-width: 560px;
}


/* logos plataformas digitales */
.sencillo .plataformas-musica a.btn.boton2 {
  font-size: .7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 5rem;
}
  .sencillo .plataformas-musica a.btn.boton2 svg {
    width: 2rem;
  }

  .sencillo .foto.parte img {
    width: 100%;
  }

@media only screen and (max-width: 1100px) {
  .cl-info {
    justify-content: space-between;
  }
    .cl-descrip-more {
      width: 40%;
    }
}

@media only screen and (max-width: 1100px) {
  .content #cla.cl-descrip {
    padding: 2rem 0 .5rem 0;
  }
}

@media only screen and (max-width: 620px) {
  /* CLASES MAS INFO */
  .cl-info {
    justify-content: space-between;
    padding: 2rem 1rem;
  }
    .cl-descrip-more {
      width: 90%;
      font-size: 0.9rem;
    }

  .cl-descrip .circle-icon {
    width: 6.2rem; height: 6.4rem;
  }
}
