.cl-descrip.contenido-blog {
  max-width: 500px;
}

.cl-descrip.contenido-blog h3 {
  width: 100%;
}

.blog-sec {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.blog-sec .row {
  width: 100%;
  margin: 0;
}
  .blog-sec .a {
    background: var(--background-card);
    border-right: 2px dashed var(--texto-claro);
  }
    .blog-sec ul {
      padding: 2rem;
      list-style: none;
    }
      .blog-sec .a ul .texto-titulo {
        color: var(--color-terciario);
        background: var(--background-card-inv);
        font-size: 3.6rem;
        padding: 0 .5rem;
      }
      .blog-sec li {
        margin: 1.4rem 1rem;
        font-size: 1rem;
      }
        .blog-sec li .btn {
          font-weight: 700;
          text-align: left;
        }

    .blog-sec div.col-sm-2 {
      min-width: 35%; /* ancho columna (a) seleccion articulo */
    }

  .blog-sec .b, .blog-sec .a {
    width: 100%;
    padding: 0;
  }

  /* Article Format */
  .blog-sec .b {
    font-size: 1rem;
    line-height: 1.8;
    padding-top: 1.6rem;
  }


/**** ****/
/* .blog-sec .cl-descrip {
  width: 70%;
} */
  .blog-sec h3 {
    width: 100%;
  }
  .blog-sec p {
    border: none;
    padding: 0;
  }
  .blog-sec .image {
    display: block;
    width: 100%;
    margin: 2rem auto;

    /*border: 1px solid hsl(210, 20%, 80%);*/
    border-radius: 1rem;

  }
    /* imagen card */
    .blog-sec .card {
      width: 100%;
      margin: 0 auto;
      /* border: 2px dashed var(--color-terciario); */
      border-radius: 2px;
    }

  .blog-sec ul {
    padding-left: 1rem;
}

.toggleListButton {
  display: none;
}

.btn-anterior-proximo {
  flex-wrap: wrap;
}
  .btn-anterior-proximo .btn {
    margin: 0 1rem;
  }


  /* BOTON SUBIR */
  /*
  .blog-sec .subir {
    margin: 0 auto;
    padding: .5rem 2rem;
    border: none;
      border-radius: 2rem;
      background-color: hsl(200, 40%, 50%);
      color: white;
  }
    .blog-sec .subir i {
      font-size: 1.2rem;
      margin-left: .6rem;
    }
  */



@media only screen and (max-width: 991px) {
  .blog-sec div.a {
    position: absolute;
      z-index: 20;
      height: 100%;
      /* background-color: hsla(160, 10%, 94%, 0.95); */
  }
    .blog-sec .row {
      display: inline-block;
    }
    .blog-sec div.col-sm-2 {
      max-width: 60%; /* ancho columna seleccion articulo */
    }
    .toggleListButton {
      position: absolute;
      top: 0rem; right: -4rem;
        display: inline-block;
        border: none;
        background-color: transparent;
        color: var(--color-secundario);
        font-size: 3rem;
    }
    .blog-sec .filter {
      position: absolute;
      width: 100%; height: 100%;
      background-color: hsla(0, 0%, 0%, 0.5);
      z-index: 15;
      transition: 0.4s;
    }

    .blog-sec div.closed {
        left: -60%; /* tiene q coincidir con 111 */
      transition: 0.4s;
    }
    .blog-sec div.open {
        width: 60%;
        left: 0;
      transition: 0.4s;
    }
}

@media only screen and (max-width: 768px) {
  .blog-sec div.col-sm-2 {
    max-width: 75%;
  }
    .blog-sec div.open {
      width: 75%;
    }
    .blog-sec div.closed {
      left: -75%;
    }

    .toggleListButton {
      top: -0.2rem; right: -4rem;
      font-size: 2rem;
    }

  /* ancho articulo */
  .blog-sec .cl-descrip {
    width: 80%;

    padding-bottom: 1.8rem;
  }
    .blog-sec .cl-descrip p {
      margin-bottom: 0;
    }

  .btn-anterior-proximo {
    justify-content: center;
  }
}

@media only screen and (max-width: 486px) {
  .blog-sec div.col-sm-2 {
    max-width: 90%;
  }
    .blog-sec div.open {
      width: 90%;
    }
    .blog-sec div.closed {
      left: -90%;
    }


  /* ancho articulo */
  .blog-sec .cl-descrip {
    font-size: 1rem;
  }

    /* imagen card */
    .blog-sec .card {
      width: 100%;
    }
}
