/*** SECTION CONTENT ***/
.section-content {
  position: relative;
  width: 100%;
    /*padding-top: 3rem;*/
  min-height: 100vh;
}

/* ajustar ancho del párrafo */
.contenido-texto {
  max-width: 1500px;
  margin: 0 auto;
}



/* IMPORTADO DE GROOVITMO */

/* COLORES */
:root {
  /* --color-primario: #4056A1;
  --color-secundario: #D79922;
  --color-terciario: #F13C20;

  --color-primario-suave: #C5CBE3;
  --color-secundario-suave: #EFE2BA;
  
  --background-primario: linear-gradient(45deg, #C5CBE3, #EFE2BA);
  --background-secundario: hsl(0, 4%, 98%);
  
  --texto-oscuro: hsl(0, 0%, 15%);
  --texto-claro: white; */
  
  --color-primario: #3B49DB;
  --color-secundario: #EB3097;
  --color-secundario-suave: #EB71B4;
  --color-terciario: #3BEBA5;
  --color-cuatro: #A335F2;
  --color-cinco: #35BEF2;
  
  --background-primario: hsl(0, 4%, 98%);

  --background-secundario: linear-gradient(45deg, var(--color-cinco), var(--color-terciario));

  --background-terciario: linear-gradient(175deg, var(--background-primario), var(--color-cinco), var(--background-primario));

  --background-card: linear-gradient(135deg, var(--color-cinco), var(--color-primario));
  --background-card-inv: linear-gradient(135deg, var(--color-primario), var(--color-cinco));

  --background-oscuro: #2631AD;
  
  --texto-oscuro: hsl(0, 0%, 15%);
  --texto-claro: white;
  --texto-gris: hsl(0, 0%, 60%);

  --color-sombra: hsla(0, 0%, 20%, .2);
}

.texto-violeta {
  color: var(--color-cuatro);
}
.texto-primario {
  color: var(--color-primario);
}
.texto-secundario {
  color: var(--color-secundario);
}

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: var(--texto-oscuro);
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

/* button:focus, a:focus { outline: 0; } */

/* Botones rellenos */
.btn { /* fondo verde */
  border: 2px solid var(--color-primario);
  background-color: var(--color-terciario);
  border-radius: 2rem;
  color: var(--color-primario);
  font-size: 1rem;
}
  .btn:hover {
    border: 2px dashed var(--background-primario);
    background-color: var(--color-cinco);
    color: var(--texto-claro);
  }
.btn.boton2 { /* fondo rojo */
  border: 2px solid var(--color-secundario);
  background-color: var(--color-secundario);
  border-radius: 2rem;
  color: var(--texto-claro);
  font-size: .9rem;
}
  .btn.boton2:hover {
    border: 2px dashed var(--background-primario);
    background-color: var(--color-cinco);
    color: var(--texto-claro);
  }
  
  
/* botones solo borde */
.btn.secundario { /*  */
  border: 1px solid var(--color-terciario);
  background-color: transparent;
  color: var(--color-terciario) !important;
}
  .btn.secundario:hover {
    /* border: 1px solid var(--color-secundario); */
    border: 1px dashed var(--background-primario);
    background-color: var(--color-cinco);
    color: var(--texto-claro) !important;
  }
.btn.terciario {
  border: 1px solid var(--color-secundario);
  background-color: transparent;
  border-radius: 2rem;
  color: var(--color-secundario);
  font-size: .9rem;
}
  .btn.terciario:hover {
    /* border: 1px solid var(--color-cinco); */
    border: 1px dashed var(--background-primario);
    background-color: var(--color-cinco);
    color: var(--texto-claro);
  }

/* SIN BORDE */
.btn.sin-borde {
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 2rem;
  color: var(--texto-claro);
}
  .btn.sin-borde:hover {
    /* border: 1px solid var(--color-cinco); */
    border: 1px dashed var(--background-primario);
    background-color: var(--color-cinco);
    color: var(--texto-claro);
    border-radius: 50% .5rem;
    transition: .2s;
  }
  .btn.sin-borde:focus {
    /* border: 1px solid var(--color-cinco); */
    /* background-color: var(--color-cinco); */
    /* color: var(--texto-claro); */
    border: 1px dashed var(--background-primario);
    border-radius: 50% .5rem;
  }

/* tamaño spinner dentro de un botón */
button .spinner-border, .btn .spinner-border {
  width: 1rem; 
  height: 1rem;
  margin-right: 1em;
}

/* CARD TESTIMONIOS */
.card { /* fondo azul gradiente */
  border: 2px dashed var(--background-primario);
  background: var(--background-card-inv);
  border-radius: 2rem;
  color: var(--texto-claro);
  font-size: 1rem;
}


/* TEXTO */
p {
  margin: 0;
}

.font-marca {
  /*font-family: 'Unica One', sans-serif;*/
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: .05em;
}

span.resaltado {
  display: inline-block;
  transform: rotate(1deg);
  background-color: var(--color-terciario);
  color: var(--color-secundario);
  padding: 4px;
}
span.resaltado2 {
  display: inline-block;
  transform: rotate(1deg);
  background-color: var(--color-terciario);
  color: var(--color-primario);
  padding: 4px;
}

.texto-normal { /* sin uso. texto std es KRUB */
  font-family: 'Krub', sans-serif;
}

.texto-titulo {
  font-family: 'Staatliches', 'Krub', 'Helvetica', sans-serif;
}

.texto-cursiva {
  font-family: 'Caveat', 'Krub', 'Helvetica', sans-serif;
  line-height: 1em;
}


.centro {
  display: flex;
  justify-content: center;
  align-items: center;
}