/*** FOOTER ***/
footer {
  /*position: relative;
    bottom: 0;*/
    width: 100%;
    /* max-height: 200px; */
  overflow: hidden;
  padding: 1rem 0;
  background-color: var(--color-primario);
  box-shadow: 1px 0 10px 0 hsl(210, 0%, 75%);
  color: var(--texto-claro);
  font-size: 0.8rem;
}
  .foot-box {
    width: 70%;
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    border-bottom: 1px solid var(--texto-gris);
  }
  .items {
    padding: 0 2rem;
  }
    .items h5, .items a {
      display: block;
      padding-top: 0.3rem;
    }
      .items h5 {
        color: var(--texto-claro);
        font-size: 1.6rem;
      }
      .items a {
        color: var(--texto-claro);
        font-size: 0.9rem;
      }
      .items a:hover {
        color: var(--color-terciario);
      }

    /* Svg icons */
    footer .items svg.svg-icon.rrss {
      width: 1.4rem; height: 1.4rem;
      margin-right: 5px;
    }
      footer .items svg.svg-icon.rrss path {
        fill: var(--texto-claro);
      }
      /* footer .items svg.svg-icon.rrss path:hover {
        fill: var(--color-terciario);
      } */

  .cr {
    padding: 1rem 0;
    text-align: center;
  }

  .data-fiscal {
    box-sizing: content-box;
    width: 40px;
  }
    .data-fiscal img {
      width: 100%; height: auto;
    }



@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 768px) {
  footer {
    width: 100%;
    padding: 0;
  }
    .foot-box {
      width: 100%;
      padding: 1rem 0;
      justify-content: center;
      flex-wrap: wrap;
    }
    .items {
      padding: 0 1rem;
    }
      .items p {
        padding-top: 0.3rem;
        font-size: 0.9rem;
      }

      .items h5 {
        font-size: 1.4rem;
      }
}

@media only screen and (max-width: 486px) {

}
