

#contact .cl-descrip {
  /*width: 50%;*/
}
  .cont-data {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
    .cont-data .data-item, .cont-data .data-item .comment {
    border: none;
    padding: 1rem;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.6;
    }
    .cont-data span {
      font-size: 1rem;
      font-weight: bold;
    }
      .email-icon {
        display: none;
      }
      .mobile-tel, .desktop-tel {
        font-weight: 400 !important;
      }
  #contact a {
    color: black;
  }
  #contact a:hover {
    color: hsl(210, 10%, 60%);
  }
  #contact a span {
    font-style: normal;
    font-weight: normal;
  }
    #contact .cl-descrip p .desktop-tel {
      font-size: 0.9rem;
      font-weight: normal;
    }
    .wapp {
      display: none;
    }

    #contact h3 {
      width: 100%;
      text-align: center;
    }


  /**** BOTON MENSAJE FIXED ***/
  #contact .mensaje {
    position: fixed;
      bottom: 2rem; right: 2rem;
      width: 5rem; height: 5rem;

    /* border: 2px solid hsl(200, 60%, 60%); */
      border-radius: 100%;
      box-shadow: 2px 2px .3rem 0 hsla(0, 0%, 0%, .2);
    z-index: 100;
  }
    #contact .mensaje i {
      position: relative;
        right: .1rem;
      font-size: 2rem;
    }


  /* ** modal ** */
  .modal-fondo {
    background: var(--background-card-inv);
    color: var(--texto-claro);
  }


@media only screen and (max-width: 991px) {
  #contact h3 {
    width: 100%;
    text-align: center;
  }
  #contact .cl-descrip p {
    text-align: center;
    border: none;
  }
  .cont-data {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  #contact .cl-descrip {
    width: 90%;
  }
    /*#contact h3 {
      width: 100%;
      text-align: left;
    }*/
    #contact .cl-descrip .comment {
      text-align: left;
      margin: 1.2rem 0;
      padding: 0 2rem;
      border-left: 0.1rem solid hsla(210,50%,40%, 0.5);
    }
    #contact .cl-descrip .cont-data .data-item {
      width: 100%;
      /*justify-content: flex-end;
      align-content: flex-end;*/
      text-align: center;

      margin: 1rem 0;
      padding: 0 1rem;
      border-left: none;
      /* border-right: 0.1rem solid hsla(210,50%,40%, 0.5); */
    }

    #contact a {
      /* display: block; */
      text-decoration: none;
    }
      #contact .dir a {
        margin: 0.2rem 0;
      }

    #contact div.celular {
      display: flex;
        justify-content: flex-end;
    }
      .wapp {
        display: inline-block;
        /*margin-right: 1rem;*/
        display: flex;
          align-items: center;

        border: none;
        font-size: 2rem;
        background-color: transparent;
      }
        .wapp:hover {
          color: hsl(0, 0%, 60%);
        }
        .wapp i {
          color: hsl(160, 40%, 40%);
        }
        .desktop-tel {
          display: none;
        }
        .mobile-tel {
          padding: 0 0 0 0.5rem;
        }

    #contact .mail {
      /* display: flex;
      align-items: center;
      justify-content: flex-end; */
    }
      .email-icon {
        display: inline-block;
        margin-right: 0.5rem;
        font-size: 1.6rem;
        color: hsl(10, 30%, 50%);
      }
}

@media only screen and (max-width: 486px) {

  /* contacto */
  .form {
    padding: 1rem 0 0 0;
  }

  .form .center-y {
    display: flex;
    flex-direction: column;
  }
    .form iframe {
      padding-top: 0.5rem !important;
    }
  #contact .comment {
    margin-top: 0 !important;
  }
}
