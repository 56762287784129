/**** LISTA ITEMS ****/

.lista {
  box-sizing: border-box;
  width: 32%;
}

.lista .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lista .prevItem {
  /*height: 4rem;*/

  margin: 0.5rem;
  border: 3px solid var(--color-cinco);
  border-radius: 2rem;
  overflow: hidden;
  transition: 0.25s;
}
  .lista .prevItem:hover {
    box-shadow: 2px 2px 10px 0 hsla(0, 0%, 0%, 0.5);
    border: 3px dashed var(--color-terciario);
    cursor: pointer;
  }

  .lista-etiquetas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lista .icon {
    /* flex-direction: column; */
    align-items: center;
    padding: .8rem;
    /* width: 100%; */

    color: var(--texto-claro);
    /* border-right: 1px solid grey; */
  }
    .lista .icon i {
      font-size: 1.4rem;
    }
    .lista .icon p {
      color: var(--texto-claro);
      margin: 0;
      margin-left: 1em;
    }
    /* formato fondo categorias */
    .transcripcion { background-color: var(--color-terciario); }
    .grooves { background-color: var(--color-cinco); }
    .tecnica { background-color: var(--color-secundario); }
    
    /* color tititulo por categ */
    .titulo-transcripcion {
      color: var(--color-terciario); 
      text-shadow: 2px 2px 3px var(--background-oscuro);
    }
    .titulo-grooves { 
      color: var(--color-cinco);
      text-shadow: 2px 2px 3px var(--background-oscuro); 
    }
    .titulo-tecnica {
      color: var(--color-secundario);
      text-shadow: 2px 2px 3px var(--background-oscuro); 
    }


  /* miniatura */
  .lista .mini {
    border-left: 1px solid grey;
  }
  .lista .detalles {
    position: relative;
    padding: 1rem;
    text-align: left;
    background: var(--background-card-inv); /* hsl(180, 50%, 98%); */
    background-size: cover;
  }
    .lista .filtro {
      position: absolute;
        top: 0; left: 0;
      width: 100%; height: 100%;
      /*background: linear-gradient(to top, hsla(180, 10%, 85%, .9), hsla(180, 10%, 96%, .8));*/
      z-index: 1;
    }
      .lista h2 {
        letter-spacing: .05em;
      }
      .lista p {
        position: relative;
          z-index: 10;

        color: var(--texto-claro);
          /*text-shadow: 1px 1px 6px hsla(0, 0%, 0%, .8);*/
          /*background-color: hsla(0, 0%, 100%, .4);*/
        /*font-weight: 700;*/
      }

      

    .lista h2 small {
      font-size: 1.2rem;
        /* color: hsl(0, 0%, 40%); */
        text-align: right;
    }
    .lista p {
      margin: 0.5rem 0 0 0;
    }



@media only screen and (max-width: 991px) {
  .lista {
    padding: 0;
    width: 48%;
  }
  .lista .prevItem {
    /* width: 100%; */
  }
    .lista .icon i {
      /* font-size: 2.8rem; */
    }
    .icon {
    }

  .lista .detalles {
  }
    .lista .detalles h2 {
      font-size: 1.6rem;
    }
    .lista .detalles p {
    }
}

@media only screen and (max-width: 768px) {
  .lista .icon i {
    font-size: 1.6rem;
    margin-right: 1rem;
  }
    .lista .detalles h2 {
      /* font-size: 1.2rem; */
    }

  /* miniatura */
  .lista .mini {
    min-height: 8rem;
    border: none;
  }
  .lista .icon {
    /* flex-direction: row; */
    border: none;
  }
  .lista p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .lista {
    width: 100%;
  }

}
