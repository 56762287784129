/*** CONTENT ***/
#box-list {
  width: 80%;
  max-width: 1200px;
  /* min-width: 800px; */
}

/* formato lista esta en Partituras.css */
.item-container {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
  /* min-width: 600px; */
}


/**** VIEW ****/
.view {
  position: fixed;
  left: 0;
  /*top: 4rem;*/
  width: 100%;
  /*padding-bottom: 1rem;*/

  z-index: 100;
}

.back-button, .video-button {
  border: none;
  border-radius: 2rem;

  color: white;

  /*border: 1px solid hsl(0, 0%, 50%);*/
  z-index: 90;
  position: fixed;

  width: 4rem; height: 4rem;
  padding: 0rem;

  border-radius: 100%;
  box-shadow: 0.1rem 0.1rem .5rem 0 hsla(0, 0%, 20%, .6);
}
  .back-button {
    bottom: 2rem; right: 2rem;
    background-color: var(--color-terciario);
  }
    .back-button i {
      font-size: 2rem;
    }
  .video-button {
    bottom: 8rem; right: 2rem;
    background-color: var(--color-secundario);
  }
    .video-button i {
      position: relative;
      top: 0.05rem; left: 0.1rem;
      font-size: 1.6rem;
    }



@media only screen and (max-width: 768px) {
  #box-list {
    width: 90%;
  }
}
