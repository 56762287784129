/* ACERCA */
#bg2 .cl-descrip {
  padding-bottom: 1.5rem;
}
  #bg2 .cl-descrip-r {
    margin-bottom: 1rem;
  }
  #bg2 h6 {
    display: inline-block;
    cursor: pointer;
    margin-left: 1rem;
  }
  #bg2 h6 a {
    color: var(--color-secundario);
    font-size: 4rem;
  }

  .cl-descrip-r {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .cl-descrip-r p {
    text-align: right;
    border: none;
  }
    p#top {
      margin-top: 0;
    }
    p#bot {
      margin-bottom: 0;
    }
    .form-items p {
      margin: 1rem 0;
    }
    .form-items {
      border-right: 2px solid var(--color-cinco);
    }
  .italic {
    font-style: italic;
  }
  .cl-descrip-r h3 {
    text-align: right;
    margin-bottom: 2rem;
  }

/* + ICON */
.bio i {
  font-size: 3rem;
}
  h6.bio:hover {
    transform: rotate(1turn);
    transition: 0.5s;
    text-decoration: none;
  }


/* TESTIMNOMIOS */
.testimonios {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
  .testimonios .card {
    margin: .6rem;
    max-width: 20rem;
    min-width: 200px;
  }
    .testimonios .card p {
      border: none;
      padding: 0;
      margin: .4rem;
      text-align: left;
    }
    .testimonios .card b {
      text-align: right;
    }
    .testimonios .card img {
      border-radius: 100%;
    }

  /* .testimonios .media-body {
    color: var(--texto-claro)
  } */


@media only screen and (max-width: 991px) {
  .content .cl-descrip {
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
  }
  .cl-descrip h3 {
    width: 70%;
  }
  .media .cl-descrip {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 486px) {
}
