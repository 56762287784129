.blog-n1 {
}

.blog-n1 iframe {
  width: 560px;
  height: 315px;

  margin: 0rem auto;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .blog-n1 iframe {
    width: 100%;
    height: auto;
  }
}
