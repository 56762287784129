/**** FORM ****/


.login .form {
  position: relative;
  padding: 4rem 0;
  max-width: 500px;
}

.login .bassman {
  position: absolute;
  top: 240px;
  left: -200px;
  transform: rotate(45deg);
  overflow: hidden;
}

  .login form {
    display: flex;
    flex-direction: column;

    margin-top: 3rem;
  }
    .login .form-group {
      margin-bottom: 0;
    }
    .login .form .form-control {
      width: 95%;
        margin: 0 0 0.4rem 0;
      /*border: 1px solid hsl(0, 0%, 60%);*/
      border: none;
      border-radius: 0.5rem;
      box-shadow: none;
      /*box-shadow: 1px 1px 0.2rem 0 hsla(0, 0%, 0%, 0.1);*/
      background-color: hsla(210, 70%, 98%, 0.85);
      border: 2px solid var(--color-terciario);
    }

    @media only screen and (max-width: 992px) {
      .login .form .container {
        max-width: 500px;
      }
    }

  .login .form .btn {
    margin: 0 auto;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    font-weight: 700;
  }


/*
.login .center-y {
  display: flex;
  justify-content: center;
}
*/
