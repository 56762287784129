/* gral */

.part #cl-desc {
  width: 100%;
}
.part .cl-descrip h3, .part .subtitle  {
  width: 100%;
  text-align: center;
  color: hsl(0, 0%, 20%);
}
  .part .cl-descrip .title {
    /* color: hsl(210, 50%, 40%); */
    color: var(--color-cinco);
    font-size: 3.4rem;
  }
  .part p.subtitle {
    margin: 1rem 0;
    font-size: 1rem;
  }

.part .cl-descrip h4 {
  font-family: 'Bubbler One', sans-serif;
}

.part .cl-descrip p {
  border: none;
  padding: 0;
}

.part #list {
  margin: 4rem 0;
  list-style: none;
  text-align: center;
}
  .part #list a {
    display: block;
    padding: 0.6rem;
    margin: 0.6rem;
  }
  .part #list a:hover {
    color: white;
    background-color: hsl(220, 40%, 50%);
    border-radius: 10px;
    cursor: pointer;
  }
  .part #list a:focus {
    border: none;
  }

  .part .subtitle a {
    color: hsl(160, 50%, 50%);
  }

  .part #main { /* solo para lista-partes */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

    .part .erase-message {
      display: none;
      width: 100%;
    }
      .part #bg2 {
        width: 100%;
      }

    iframe.view-pdf {
      border: none;
      min-height: 40rem;
    }



@media only screen and (max-width: 600px) {
  .part #bg2 {
    width: 95%;
  }
    .part #box-list {
      /*margin-top: 2.5rem;*/
    }
  .part .cl-descrip .title {
    font-size: 2.4rem;
  }
    .part h3.subtitle {
      font-size: 1.2rem;
    }
  .part td {
    text-align: left;
    display: block;
  }
    .part label {
      display: block;
      margin-top: 1rem;
    }
    .part label.lab-margin {
      margin-left: 0;
    }
  .part .items a, .part .items h5 {
    font-size: 0.7rem;
  }

  .part .cr {
    padding-bottom: 1em;
    font-size: 0.7rem;
  }

}

@media only screen and (max-width: 480px) {
  .part .foot-box {
    justify-content: space-around;
  }

    .part .items {
      padding: 0 0.2rem;
    }
}
