.back {
  width: 100%;
  background-size: cover;
  /*background-attachment: fixed;*/
}

main a:hover {
  text-decoration: none;
}

.lovdisyit-main .back1 {
  position: relative;
  max-height: 500px; /*32*/
  width: 100%;
  
  background-image: url("/assets/img/lovdisyit_500px.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  display: flex;
  justify-content: center;
}
  .lovdisyit-main .back1 img {
    position: relative;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .lovdisyit-main .back1 .filter {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: hsla(246, 100%, 12%, 0.4);
    backdrop-filter: blur(5px);
  }

  .space {
    height: 6rem;
  }
  .content {
    background-color: hsl(180, 20%, 96%);
}

.lovdisyit-main .plataformas-musica {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  .lovdisyit-main .plataformas-musica a {
    display: flex;
    flex-direction: column;
    margin: .2em;
  }

.lovdisyit-main .plataformas-musica svg {
  fill: white;
  width: 4rem;
}
.lovdisyit-main .texto-titulo {
  font-size: 6rem;
}

.lovdisyit-main .spoti {
  display: flex;
    justify-content: center;
}
  .lovdisyit-main .spoti.mobile {
    display: none;
  }

  .lovdisyit-main .spoti iframe {
    width: 100%;
  }

  .lovdisyit-main .foto {
    width: 100%;
    border:4px dashed var(--background-primario);
    filter: saturate(125%);
    overflow: hidden;
  }
    .lovdisyit-main .foto-filter {
      width: 100%;
      height: 20rem;
      background-color: hsla(0, 91%, 50%, 0.1);
    }


.space {
  background-color: hsla(215, 75%, 60%, 0.45);
}

  .back2 {
    background-image: url('/assets/img/clases.jpg');
    background-attachment: fixed;
    /* background-position: 0 -36rem; */
  }


  .back3 {
    /*background-image: url("/assets/img/bajos.jpg");*/
    background-attachment: fixed;
  }
    .back3 .space {
      background-color: hsla(0, 10%, 0%, 0.2);
    }


  main .back4 {
    background-image: url("/assets/img/bajos.jpg");
    background-attachment: fixed;
    /* background-position: 0 -36rem; */
  }


@media only screen and (min-width: 1600px) {
  main .back1 {
    /* height: 95vh;
    background-position: top; */
    /* background-position: 0rem -14rem; */
  }
}

@media only screen and (max-width: 991px) {
  main .back1 {
    /* height: 20rem;
    background-position: 0rem -6rem; */
    /* height: 50vh;
    background-position: top; */
  }
}

@media only screen and (max-width: 768px) {
  main .back {
    background-attachment: local;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  /* main .back1 {
    height: 18rem;
  } */
  .lovdisyit-main .back1 {
    height: auto;
    width: 100%;
  }
    .lovdisyit-main .back1 img {
      width: 100%;
        max-width: 500px;
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

  main .back2 {
    background-position: 0 -12rem;
  }
  .back3 {
    background-position: 0 -24rem;
  }
  .back4 {
    background-position: 0 -14rem;
  }

  .lovdisyit-main .spoti.mobile {
    display: inherit;
  }
  .lovdisyit-main .spoti.desktop {
    display: none;
  }

}

@media only screen and (max-width: 486px) {
  main .back1 {
    /* height: 11rem; */
    /* background-position: 0rem -1rem; */
  }
    .espacio-portada {
      /*height: 12rem;*/
    }

  main .back2 {
    background-position: 0 -8rem;
  }
  /*.back3 {
    background-position: 0 -12rem;
  }*/
  .back4 {
    background-position: 0 -6rem;
  }
    #contacto {
      height: 7rem;
    }

  .lovdisyit-main .texto-titulo {
    font-size: 4rem;
  }
}
