.playBlog {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
  .playBlog iframe {
    width: 100%;
    min-height: 315px;
    display: block;
  }

  .cl-descrip .alert.blog-alert {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    border: 4px dashed var(--color-primario);
    background: var(--background-secundario);
  }


@media only screen and (max-width: 991px) {
    .playBlog {
      /* width: 100%;
      height: 360px; */
    }
}

@media only screen and (max-width: 768px) {
  .playBlog {
    /* width: 100%;
    height: 280px; */
  }
}

@media only screen and (max-width: 486px) {
  .playBlog {
    /* width: 100%;
    height: 200px; */
  }
}

@media only screen and (min-width: 1500px) {
  .playBlog {
    /* width: 70%;
    height: 370px; */
  }
}
