body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  /* max-width: 2000px; */
  margin: 0 auto;
  background-color: hsl(180, 20%, 96%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  /*box-sizing: content-box;*/
  margin: 0;
  padding: 0;
}

html {
  font-size: 15.4px;
}

html, body {
  cursor: default;
  font-family: 'Krub', 'Arial', sans-serif;
}

p {
  margin: 0;
}

.btn:focus, button:focus {
  outline: none;
}
