.media .vid-container {
  padding-bottom: 1rem;
}


.videos {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 2rem 0 5rem 0;
}
  .v {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0.2rem;
    overflow: hidden;
    transition: 0.2s;
  }
  .v {
    position: relative;
    width: 24%;
    z-index: 10;
  }
    .v:hover {
      cursor: pointer !important;
      filter: brightness(120%);
    }
    .v h2, .v h4 {
      position: absolute;
        top: 30%; left: 10%;
        z-index: 50;

      color: white;
        text-shadow: 1px 1px 3px black;
        font-family: 'Libre Franklin', Helvetica, sans-serif;
    }
      .v h2 {
          font-size: 3rem;
      }
      .v h4 {
        top: 51%; left: 25%;
          font-size: 1.8rem;
      }


    .v1 { background-image: url("/assets/img/media/pedro.jpg"); }
    .v2 { background-image: url("/assets/img/media/palm.jpg"); }
    .v3 { background-image: url("/assets/img/media/jaco.jpg"); }
    .v4 { background-image: url("/assets/img/media/tips.jpg"); }
    .b1 { background-image: url("/assets/img/media/blog1.jpg"); }
    .b2 { background-image: url("/assets/img/media/blog2.jpg"); }
    .b3 { background-image: url("/assets/img/media/blog3.jpg"); }

    .videos img, .blog img {
      width: 100%;
      height: auto;
      display: block;
        border-radius: .5rem;
    }
    .videos img { visibility: visible; }
    .blog img { visibility: hidden; }

      .img-over {
        background-size: 120%;
        opacity: 0.8;
        transition: 0.2s;
      }

/* Video rep */
.hide {
  display: none;
  position: relative;
  z-index: 40;
}
.vid-rep {
  position: fixed;
  top: 0; left: 0;
  z-index: 45;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 50%, 0%, 0.7);
}
  .play {
    position: relative;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 600px;
  }
    .vid-rep iframe {
      width: 100%;
      height: 100%;
      display: block;
    }
  #video1 { display: none; }
  #video2 { display: none; }
  #video3 { display: none; }
  #video4 { display: none; }

  #cancel {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 17;
    font-size: 4rem;
    color: hsl(0, 5%, 80%);
  }
   .cancel-over { color: white; }



@media only screen and (max-width: 991px) {
  .media .cl-descrip {
    width: 100%;
  }
  #blog-pad .cl-descrip {
    padding-top: 0.5rem;
  }
  #vid-pad .container, #blog-pad .cl-descrip {
    width: 90% !important;
  }
    .v {
      width: 32%;
    }
    .b {
      width: 30%;
    }
    /* vid rep */
    .play {
      width: 100%;
      height: 400px;
    }
    #cancel {
      font-size: 3rem;
    }
}

@media only screen and (max-width: 768px) {
  .media .cl-descrip {
    width: 100%;
    padding-bottom: 1.8rem;
  }
    .media .cl-descrip p {

    }


    .v {
      width: 48%;
    }
    .b {
      width: 100%;
    }
  /* vid rep */
  .play {
    width: 100%;
    height: 300px;
  }
  #cancel {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 486px) {
  .v {
    width: 100%;
  }
}
