
div.espacio-portada {
  /*height: 28rem;*/
  height: 0;
}


/* Divisores separados */
svg {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1;
}
#sec-div-top {
  top: 6rem;
  background-color: transparent;
}
#sec-div-bottom {
  top: -4rem;
  background-color: transparent;
}
.sec-div {
  fill: var(--color-primario);
}


#intro {
  position: relative;
  width: 100%;
}
.inner-intro-cont {
  position: relative;
  z-index: 20;
  width: 100%;
  background: var(--background-card);
}

#intro .container {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: hsl(220,40%,90%);
}
  #intro .col-2 {
    margin: 0rem;
    padding-left: 1.5rem;
    border-left: 2px solid hsla(210,50%,90%, 0.5);

    font-size: 1.1rem;
    font-weight: 200;
    /*font-style: italic;*/
  }
    #intro .col-2 ul li{
      list-style: none;
      padding: 0.5rem 0;
  }
    #intro .titulo1 {
      text-align: center;
      text-transform: uppercase;
      /* font-family: 'Bubbler One', sans-serif; */
        font-size: 3.2rem;
      margin: 0;
    }
      #intro .leyend {
        text-align: center;
        margin: 0;
        font-size: 1.2rem;
        font-style: italic;
      }

@media only screen and (min-width: 2000px) {
  div.espacio-portada {
    /*height: 32rem;*/
  }
}

@media only screen and (max-width: 1200px) {
  .espacio-portada {
    /*height: 18rem;*/
  }

  #sec-div-top {
    top: 4rem;
    background-color: transparent;
  }
  #sec-div-bottom {
    top: -4rem;
    background-color: transparent;
  }

  #intro .container {
    max-width: 90%;
  }
    #intro .col-1 {
    }
      #intro .col-1 h3 {
        font-size: 2rem;
      }
    #intro .col-2 {
    }
}

@media only screen and (max-width: 768px) {
  .espacio-portada {
    /*height: 16rem;*/
  }

    #intro .titulo1 {
      font-size: 2.4rem;
    }
      #intro .container {
        padding: 1.4rem 0;
      }

  #sec-div-top {
    top: 1rem;
    background-color: transparent;
  }
  #sec-div-bottom {
    top: -1rem;
    background-color: transparent;
  }

  #intro .container {
    max-width: 100%;
    /*background-color: red;*/
  }
    #intro .col-1, #intro .col-2 {
      max-width: 50%;
      flex: 40%;
    }
      #intro .col-1 h3 {
        font-size: 1.8rem;
      }
      #intro .col-2 ul {
        font-size: 1rem;
      }
}

@media only screen and (max-width: 486px) {
  .espacio-portada {
    /*height: 14rem;*/
  }

  #intro .container {

  }
    #intro .col-1, #intro .col-2 {
      max-width: 100%;
    }
      #intro .col-2 {
        margin-top: 1rem;
      }
        #intro .col-1 h3 {
          font-size: 1.6rem;
        }
        #intro .col-2 ul {
          font-size: 1rem;
        }
}
